import {combineReducers} from "redux";
const initialState = {
    user: {},
}

const reducer = (state= initialState,action)=>{
    switch(action.type){
        case "SET_USER": return {
            ...state,
            user:action.payload.user,
        }
        default: return state
    }
}

export default combineReducers({
    use:reducer
})