import React,{ useState,useEffect } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import axios from "axios";

import PowerBiService from "./../../services/auth/powerBI.auth";
import config from "./../../config/PowerBI.config";

function PowerConsumption() {
  const [report, setReport] = useState();
  // const [reportRender,setReportRender] = useState();
  const [authToken, setAuthToken] = useState("");
  const [sampleReportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      localeSettings: {
        language: "en",
        formatLocale: "en-gb",
      },
    },
    viewMode: models.ViewMode.View,
  });
  const bear = "Bearer ";

  const base = config.powerConsumption.base;
  const groupID = config.powerConsumption.groupIdSolar;
  const reportID = config.powerConsumption.reportIdSolar;
  //  "datasetIdACPL": "e94517bd-69af-4906-8363-7353c00c2193",
  const reportURL = config.powerConsumption.reportURLSolar;


  const getEmbedToken = (auth) => {
    let URL = "";
    URL = base.concat(groupID, "/reports/", reportID, "/GenerateToken");

    return axios
      .post(
        URL,
        {
          accessLevel: "View",
          allowSaveAs: "false",
        },
        {
          headers: {
            Authorization: authToken === "" ? auth : authToken,
            "Content-type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log('response',  response);
        setReportConfig({
          ...sampleReportConfig,
          embedUrl: reportURL,
          accessToken: response.data.token,
        });
      })
      .catch((err) => {
        console.log(err, "accessToken error");
      });
  };

  const getAccessToken = () => {
    // const t = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCIsImtpZCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNTBkNmNiNjktOTM2Zi00YzE4LWJhY2ItMDdmZDA3OGU5YmRlLyIsImlhdCI6MTYzODg1ODYwMSwibmJmIjoxNjM4ODU4NjAxLCJleHAiOjE2Mzg4NjI1MDEsImFpbyI6IkUyWmdZSGp2OG5UUjVjOGZUZTcwWEJCY25jVDJIUUE9IiwiYXBwaWQiOiIyN2MzNzA4My00ZTc3LTQ2ODEtOWEzOC1mMzVhNTg1ZWEwOTciLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC81MGQ2Y2I2OS05MzZmLTRjMTgtYmFjYi0wN2ZkMDc4ZTliZGUvIiwib2lkIjoiNTRhMDQ5MDgtZWVjYS00YTU5LWE1ZTgtYzIwOGEwYmM3NGZhIiwicmgiOiIwLkFYSUFhY3ZXVUctVEdFeTZ5d2Y5QjQ2YjNvTnd3eWQzVG9GR21qanpXbGhlb0pkeUFBQS4iLCJzdWIiOiI1NGEwNDkwOC1lZWNhLTRhNTktYTVlOC1jMjA4YTBiYzc0ZmEiLCJ0aWQiOiI1MGQ2Y2I2OS05MzZmLTRjMTgtYmFjYi0wN2ZkMDc4ZTliZGUiLCJ1dGkiOiJycFhJUTF5NnMwU2QxdGJVSFNRUUFBIiwidmVyIjoiMS4wIn0.ImnZFUoejz-EMCJla7vmqOFsZzF4sSY3NoX2QVd7BnFfw2b9ULas_vYZ-pFCaT1ZjqqT1GlbfY_CPsCcYDB6jQ9-IAdgFCwdlIUy_tfzljpRRneQpXbPMbdCSKBZ2npAIEi70QkA6v66e2Ii8T1WnN-y4DLMakWgoKtBpAMc0hGWNtffrrCirsnwqqahrEijzyJe7LU0gd1X4OWIi9hy987oBpgtmOnVyWrmglc-RYwQ3-S7IaHfzKURLMSBRT5xQs9dCfyPoHoKxf1jYHO_GXuLwwHiYswJxa8tH5U_fURengKOOaf9gqmRszxyvnyDN7nqRjhyeuJSjMLJAB9feQ";
    // setAuthToken(bear.concat(t))
    // getEmbedToken(bear.concat(t));
    // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCIsImtpZCI6Imwzc1EtNTBjQ0g0eEJWWkxIVEd3blNSNzY4MCJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNTBkNmNiNjktOTM2Zi00YzE4LWJhY2ItMDdmZDA3OGU5YmRlLyIsImlhdCI6MTYzODg1ODYwMSwibmJmIjoxNjM4ODU4NjAxLCJleHAiOjE2Mzg4NjI1MDEsImFpbyI6IkUyWmdZSGp2OG5UUjVjOGZUZTcwWEJCY25jVDJIUUE9IiwiYXBwaWQiOiIyN2MzNzA4My00ZTc3LTQ2ODEtOWEzOC1mMzVhNTg1ZWEwOTciLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC81MGQ2Y2I2OS05MzZmLTRjMTgtYmFjYi0wN2ZkMDc4ZTliZGUvIiwib2lkIjoiNTRhMDQ5MDgtZWVjYS00YTU5LWE1ZTgtYzIwOGEwYmM3NGZhIiwicmgiOiIwLkFYSUFhY3ZXVUctVEdFeTZ5d2Y5QjQ2YjNvTnd3eWQzVG9GR21qanpXbGhlb0pkeUFBQS4iLCJzdWIiOiI1NGEwNDkwOC1lZWNhLTRhNTktYTVlOC1jMjA4YTBiYzc0ZmEiLCJ0aWQiOiI1MGQ2Y2I2OS05MzZmLTRjMTgtYmFjYi0wN2ZkMDc4ZTliZGUiLCJ1dGkiOiJycFhJUTF5NnMwU2QxdGJVSFNRUUFBIiwidmVyIjoiMS4wIn0.ImnZFUoejz-EMCJla7vmqOFsZzF4sSY3NoX2QVd7BnFfw2b9ULas_vYZ-pFCaT1ZjqqT1GlbfY_CPsCcYDB6jQ9-IAdgFCwdlIUy_tfzljpRRneQpXbPMbdCSKBZ2npAIEi70QkA6v66e2Ii8T1WnN-y4DLMakWgoKtBpAMc0hGWNtffrrCirsnwqqahrEijzyJe7LU0gd1X4OWIi9hy987oBpgtmOnVyWrmglc-RYwQ3-S7IaHfzKURLMSBRT5xQs9dCfyPoHoKxf1jYHO_GXuLwwHiYswJxa8tH5U_fURengKOOaf9gqmRszxyvnyDN7nqRjhyeuJSjMLJAB9feQ";

    PowerBiService.getAccessToken()
      .then((response) => {
        if (response.data !== undefined) {
          setAuthToken(bear.concat(response.data));
          getEmbedToken(bear.concat(response.data));
        }
      })
      .catch((error) => {
        console.log("cathc")
        console.log(error);
      });
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const eventHandlersMap = new Map([
    [
      "loaded",
      function () {
        //console.log("Report has loaded");
      },
    ],
    [
      "rendered",
      function () {
        //console.log("Report has rendered");
        // setReportRender(true);
      },
    ], //Event handler for err
    [
      "error",
      function (event) {
        if (event) {
          console.error(event.detail);
        }
      },
    ], //Event handler for Save As
    [
      "saved",
      function (event) {
        if (event) {
        }
      },
    ],
  ]);
  console.log('sampleReportConfig', sampleReportConfig);
  return (
      <PowerBIEmbed
        embedConfig={sampleReportConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={"solarPlantReport"}
        style={{ height: "100%", width: "100%" }}
        getEmbeddedComponent={(embedObject) => {
          setReport(embedObject);
        }}
      />
  );
}
export default PowerConsumption;
