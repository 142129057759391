import React from "react";
import { useState, memo } from "react";
import axios from "axios";

import Styles from "./style.module.css";
import config from "./../../config/api.config.json";
function ChangePassword() {
  const initialPAssword = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [password, setPassword] = useState(initialPAssword);
  const [hasPasswordChanged, setHasPasswordChanged] = useState("");
  const [error, setError] = useState("");

  const handleSetPassword = (e) => {
    const passwordStatus = e.target.dataset.passwordStatus;
    setPassword({
      ...password,
      [passwordStatus]: e.target.value,
    });
  };

  const handleReset = (e) => {
    setPassword(initialPAssword);
    setError("");
    setHasPasswordChanged("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = password;
    if (currentPassword === "") {
      setError("Please enter Current Password");
      setHasPasswordChanged("");
    } else if (newPassword === "") {
      setError("Please enter New Password");
      setHasPasswordChanged("");
    } else if (confirmPassword === "") {
      setError("Please enter Confirm Password");
      setHasPasswordChanged("");
    } else if (newPassword !== confirmPassword) {
      setError("New Password and Confirm Password should be same");
      setHasPasswordChanged("");
    } else {
      setError("");
      setHasPasswordChanged("");
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");
      axios
        .post(config.CHANGE_PASSWORD, { password, email,token })
        .then((res) => {
          setHasPasswordChanged("Password has changed successfully");
          setPassword(initialPAssword);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.password === "invalid") {
            setError("You entered wrong password");
          } else {
            setError("Something went wrong, Password not changed");
          }
        });
    }
  };
  return (
    <div className={`${Styles.changePassword}`}>
      {error ? (
        <div className={`${Styles.msg} ${Styles.error}`}>{error}</div>
      ) : (
        ""
      )}
      {hasPasswordChanged ? (
        <div className={`${Styles.msg} ${Styles.success}`}>
          {hasPasswordChanged}
        </div>
      ) : (
        ""
      )}
      <form action="" onReset={handleReset} onSubmit={handleSubmit}>
        <div className={`${Styles.formFields}`}>
          <div className={`${Styles.formGroup}`}>
            <label>Current Password</label>
            <input
              placeholder="enter old password"
              type="password"
              value={password.currentPassword}
              onChange={handleSetPassword}
              data-password-status="currentPassword"
            />
          </div>
          <div className={`${Styles.formGroup}`}>
            <label>New Password</label>
            <input
              placeholder="enter New password"
              type="password"
              value={password.newPassword}
              onChange={handleSetPassword}
              data-password-status="newPassword"
            />
          </div>
          <div className={`${Styles.formGroup}`}>
            <label>Confirm Password</label>
            <input
              placeholder="enter confirm password"
              type="password"
              value={password.confirmPassword}
              onChange={handleSetPassword}
              data-password-status="confirmPassword"
            />
          </div>
        </div>
        <div className={`${Styles.submitControll}`}>
          <input type="submit" value="save" className={`${Styles.btnBlue}`} />
          <input type="reset" value="clear" className={`${Styles.btnGray}`} />
        </div>
      </form>
    </div>
  );
}
export default memo(ChangePassword);
