import React from "react";
function PlantConnectivityIcon() {
    return (
        <>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="20" height="20" viewBox="0 0 220.000000 220.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,220.000000) scale(0.100000,-0.100000)"
                    fill="#5D97F6" stroke="none">
                    <path d="M1125 2236 c-38 -8 -90 -21 -115 -30 -131 -48 -284 -172 -359 -291
-39 -61 -41 -62 -93 -69 -138 -19 -248 -118 -279 -254 -11 -46 -18 -55 -55
-77 -63 -37 -139 -119 -173 -186 -102 -204 -35 -459 151 -581 97 -64 128 -71
303 -77 l160 -6 0 -200 0 -200 -73 -3 c-67 -3 -73 -1 -81 20 -12 31 -69 84
-104 97 -92 35 -205 -7 -246 -91 -70 -146 46 -307 205 -283 51 7 117 53 139
95 l16 30 134 0 135 0 2 268 3 267 133 3 132 3 0 -143 -1 -143 -43 -26 c-135
-82 -102 -306 52 -349 47 -13 67 -13 114 0 154 43 187 267 52 349 l-43 26 -1
143 0 143 133 -3 132 -3 3 -267 2 -268 135 0 134 0 16 -30 c37 -71 140 -113
223 -91 160 43 193 263 54 352 -52 33 -128 39 -185 16 -33 -14 -107 -88 -107
-107 0 -7 -27 -10 -72 -8 l-73 3 0 200 0 200 160 6 c175 6 206 13 303 77 186
122 253 377 151 581 -34 68 -110 149 -174 187 -38 22 -45 31 -45 57 0 58 -39
197 -77 273 -90 182 -266 326 -465 380 -78 21 -230 26 -313 10z m305 -144 c99
-35 159 -71 234 -143 121 -114 185 -264 186 -430 l0 -67 55 -22 c75 -30 147
-98 182 -168 37 -75 40 -191 6 -264 -32 -70 -103 -141 -176 -174 l-62 -29
-730 0 -730 0 -62 29 c-73 33 -144 104 -176 174 -34 73 -31 189 6 264 36 73
111 141 185 168 l57 21 -3 57 c-4 72 26 134 87 177 39 27 50 30 123 30 l79 0
42 80 c78 150 216 261 379 305 91 25 234 21 318 -8z m-1045 -1855 c8 -12 15
-30 15 -41 0 -28 -39 -66 -67 -66 -52 0 -84 78 -45 112 35 31 73 29 97 -5z
m788 5 c38 -33 5 -112 -48 -112 -53 0 -86 79 -47 112 33 29 60 29 95 0z m790
0 c38 -33 6 -112 -45 -112 -27 0 -68 37 -68 62 0 29 19 58 43 67 25 9 44 5 70
-17z"/>
                    <path d="M1015 1579 c-178 -27 -354 -130 -458 -268 -53 -71 -53 -73 18 -111
46 -25 46 -25 57 -5 24 43 130 143 190 178 97 58 185 81 303 81 118 0 206 -23
303 -81 60 -35 166 -135 190 -178 11 -20 11 -20 57 5 71 38 71 40 18 111 -87
114 -244 217 -387 254 -76 19 -216 26 -291 14z"/>
                    <path d="M985 1303 c-95 -34 -181 -97 -235 -174 l-19 -25 55 -33 55 -33 38 44
c22 24 63 57 92 73 50 28 62 30 154 30 92 0 104 -2 154 -30 29 -16 70 -49 92
-73 l38 -44 55 33 c51 31 54 34 40 53 -39 57 -114 120 -183 154 -70 34 -81 37
-180 40 -82 2 -117 -2 -156 -15z"/>
                    <path d="M1045 1041 c-38 -17 -93 -67 -84 -75 2 -2 26 -17 53 -34 45 -27 52
-29 70 -16 11 8 30 14 41 14 11 0 30 -6 41 -14 18 -13 25 -11 70 16 27 17 51
32 53 34 3 2 -6 16 -20 30 -33 36 -96 64 -144 64 -22 0 -58 -9 -80 -19z"/>
                </g>
            </svg>

        </>
    );
}
export default PlantConnectivityIcon;
