import { createStore,applyMiddleware} from "redux";
import CombineReducers from "./reducers";
import {persistStore,persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
    key : "persist-store",
    storage
}
const persistedReducer = persistReducer(persistConfig,CombineReducers)
export const store = createStore(persistedReducer)
export const persistedStore =  persistStore(store)