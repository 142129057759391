import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router";
import axios from 'axios';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import { styled } from '@mui/material';
import Typography from "@mui/material/Typography";
import apijson from "../../../config/api.config.json";
import "./index.css";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


export default function Connectivity() {
    const [token, setToken] = useState("");
    const [loadStatus, setLoadStatus] = useState(false);
    const [error, setError] = useState("");
    const iframeRef = useRef(null);
    const history = useHistory();
    const location = history.location.pathname.split("/");
    const pagename = location[location.length - 1]
    useEffect(() => {
        setLoadStatus(true);
        // getAuthToken();
    }, []);

    const [TabIndex, setTabIndex] = useState(0);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        //   if (newValue == 0) {
        // dispatch(UpdateSelectedMachine(""));
    //   }
    //   dispatch(UpdateTabIndex(newValue));
    };

    // const getAuthToken = async () => {
    //     try {
    //         let authToken = await axios.get(apijson.GRAFANA_AUTH)
    //         if (authToken.data.response.message && authToken.data.response.message === "Logged in") {
    //             setToken(authToken.data.response.message);
    //             setLoadStatus(false);
    //         }
    //     } catch (err) {
    //         setError(err);
    //     }
    // }
    // const getURL = (pagename) => {
    //     let urlList = {
    //         'Timeseries': `${process.env.REACT_APP_REACT_GRAFANA_URL}/d/f5e52788-f5f7-4beb-bb22-28040499aa0b/fnf-plantconnectivity-values-2?orgId=1`,
    //         'TimeSeriesValuesReport': `${process.env.REACT_APP_REACT_GRAFANA_URL}/d/b5d53c57-cee5-49b2-8c08-89a69ba1eb80/fnf-plantconnectivity-values?orgId=1`,
    //         'OverviewReport': `${process.env.REACT_APP_REACT_GRAFANA_URL}/d/df6f1d44-8fa3-49fe-8303-03dd3f92234b/fnf-plantconnectivity-overview?orgId=1`
    //     }
    //     return urlList[pagename] ? urlList[pagename] : '/';
    // }
    return (
        <div className='grafana-report' >
            {/* {
                loadStatus ?
                <p className='loadtext'>loading the report</p>
                : 
                <iframe ref={iframeRef} id="grafana-iframe" src={getURL(pagename)} width="100%" height="100vh"/>
            
            { error && <p className='loadtext'>Authentication Failed</p> } */}
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={TabIndex}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Overview" {...a11yProps(0)} className="tabs_head"
                            sx={{
                                color: "#E0E0E0",
                                textTransform: "none",
                                fontWeight: 600,
                                fontSize: "13px",
                            }}
                        />
                        <Tab label="Values" {...a11yProps(1)} className="tabs_head"
                            sx={{
                                color: "#E0E0E0",
                                textTransform: "none",
                                fontWeight: 600,
                                fontSize: "13px",
                            }}
                        />
                        <Tab label="Time Series" {...a11yProps(1)} className="tabs_head"
                            sx={{
                                color: "#E0E0E0",
                                textTransform: "none",
                                fontWeight: 600,
                                fontSize: "13px",
                            }}
                        />
                    </Tabs>
                </Box>
                <CustomTabPanel value={TabIndex} index={0}>
                    <iframe ref={iframeRef} id="grafana-iframe" src={`${process.env.REACT_APP_REACT_GRAFANA_OVERVIEW_URL}`} width="100%" height="100vh"/>
                </CustomTabPanel>
                <CustomTabPanel value={TabIndex} index={1}>
                    <iframe ref={iframeRef} id="grafana-iframe" src={`${process.env.REACT_APP_REACT_GRAFANA_VALUES_URL}`} width="100%" height="100vh"/>
                </CustomTabPanel>
                <CustomTabPanel value={TabIndex} index={2}>
                    <iframe ref={iframeRef} id="grafana-iframe" src={`${process.env.REACT_APP_REACT_GRAFANA_TIEMSERIES_URL}`} width="100%" height="100vh"/>
                </CustomTabPanel>
            </Box>
        </div>
    )
}