import React from "react";
import axios from 'axios';
import { memo, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import UserIcon from "./images/UserIcon";
import LogoutIcon from "./images/LogoutIcon";
import { useState } from "react";
import Styles from "./style.module.css";
import Logo from "./images/Logo";
import apiConfig from '../../config/api.config.json';

function Header(props) {
  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  const email = localStorage.getItem("email");
  const tile = firstname[0] + lastname[0];
  // const tile = "kk";
  const history = useHistory();
  const pageName = history.location.pathname.split("/")[1];
  const title = getTitle(pageName);
  const [isShowPanel, setIsShowPanel] = useState(false);
  const profileInfo = useRef();

  const togglePanel = (e) => {
    setIsShowPanel(!isShowPanel);
  };
  const handleDocumentClick = (e) => {
    if (profileInfo.current.contains(e.target) === false) {
      setIsShowPanel(false);
    }
  };
  const logout = (e) => {
    // setIsShowPanel(false)
    const UserID = localStorage.getItem('UserID');
    axios
    .post(apiConfig.API_LOGOUT, { UserID })
    .then((res) => {
      localStorage.clear();
      history.replace("/");
    }).catch((err) => {
      console.log('err', err);
    }) 
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  function getTitle(pagename){
    switch(pageName){
      case 'SmartMeter': return "Smart Meter";
      case "slitter": return "Films and Foils, Slitter OEE";
      case "en1m": return "Films and Foils, Energy Monitoring System";
      case "DistributionWiseAnalysis": return "Distribution Wise Consumption";
      case "ProcessWiseAnalysis": return "Process Wise Analysis";
      case "DistributionNetwork": return "Distribution Network";
      case "EMSOverview": return "Energy Management Dashboard - Overview";
      case "Smlr": return "Smart Meter Live Reading";
      case "PlantConnectivity": return "Plant Connectivity";
      case "Connectivity" : return "Connectivity Report";
      case "PowerConsumption" : return "Power Consumption";
      case "OEE" : return "Calender Coater CFB OEE";
      // case "TimeSeriesValuesReport" : return "Time Series Values"
      // case "OverviewReport" : return "Time Series Overview"
      default: return "Solar Power Plant Dashboard, Tuljapur";
    }
  }
  return (
    <header className={`${Styles.header}`}>
      <div className={`${Styles.header__left}`}>
        <div className={`${Styles.header__logo}`}>
          <Logo />
        </div>
        <div className={`${Styles.header__pageInfo}`}>
          {title}
        </div>
      </div>
{/* 
      {( pageName == 'DistributionWiseAnalysis'  )?
             <div style={{
              position: "relative",
              left: "3%"
            }}>
             
              <div >
                Max Demand for HT:81.26kw
              </div>
            </div>
       : ""} */}


      {(pageName == 'ProcessWiseAnalysis' || pageName == 'DistributionWiseAnalysis'  )?
             <div style={{
              position: "relative",
              left: "21%"
            }}>
             
              <div >
                Per Unit Rate: Rs.8.4/-
              </div>
            </div>
       : ""}


      <div className={`${Styles.profileInfo}`} ref={profileInfo}>
        <div
          className={`${Styles.profileInfo__ShortName} ${
            isShowPanel ? `${Styles.active}` : ""
          }`}
          onClick={togglePanel}
        >
          {tile}
        </div>
        <div
          className={`${Styles.profileInfo__panel} ${
            isShowPanel ? `${Styles.showPanel}` : ""
          }`}
        >
          <div className={`${Styles.profileInfo__infoContainer}`}>
            <div className={`${Styles.profileInfo__icon}`}>
              <UserIcon />
            </div>
            <div className={`${Styles.profileInfo__info}`}>
              <span className={`${Styles.profileInfo__infoLabel}`}>
                {firstname} {lastname}
              </span>
              <span className={`${Styles.profileInfo__email}`}>{email}</span>
            </div>
          </div>
          <div
            className={`${Styles.profileInfo__infoContainer} ${Styles.profileInfo__logout}`}
            onClick={logout}
          >
            <div className={`${Styles.profileInfo__icon}`}>
              <LogoutIcon />
            </div>
            <div className={`${Styles.profileInfo__info}`}>
              <span className={`${Styles.profileInfo__infoLabel}`}>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
const maptStateToProps = (state) => {
  return {
    user: state.use.user,
  };
};
export default Header;
