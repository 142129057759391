import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Sidebar from "./Sidebar/Sidebar";
function Dashboard(props) {
  const history = useHistory()
  const isValidUser = (token)=>{
    axios.post("/api/verifyToken",{token:token}).then((res)=>{
      return true;
    }).catch((err)=>{
      history.replace("/")
    })
  }
  useEffect(()=>{
    const token = localStorage.getItem("token");
    // const token = props.token;
    if(token){
      isValidUser(token,"/")
    }else{
      history.replace("/")
    }
  },[])
  return (
    <div className="dashboard">
      <Sidebar></Sidebar>
      <div className="dashboard__content">
        <Header />
        <div className="dashboard__data">
          <div className="dashboard__innerData">
            {props.children}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

const maptStateToProps = (state) =>{
  return {
    token:state.use.token
  }
}
export default connect(maptStateToProps,null)(Dashboard);
